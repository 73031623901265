global?.modules?.set({
  name: 'slider',
  is_booted: false,
  async init() {
    if (this.is_booted) return;
    this.is_booted = true;
    initShowreelSliders();
  }
});

function initShowreelSliders() {
  const sliderContainers = document.querySelectorAll('.s-showreell__body');
  if (!sliderContainers.length) return;

  sliderContainers.forEach((sliderContainer) => {
    const prevButton = sliderContainer.querySelector('.s-showreell-content__arrow-prev');
    const nextButton = sliderContainer.querySelector('.s-showreell-content__arrow-next');
    const slides = Array.from(sliderContainer.querySelectorAll('.s-showreell-content__slide'));
    const paginationButtons = Array.from(sliderContainer.querySelectorAll('.s-showreell-content__pagination button'));

    // Якщо слайдів немає, нічого не робимо
    if (!slides.length) return;

    // Якщо кількість слайдів не відповідає кількості пагінацій або лише один слайд – активуємо перший і виходимо
    if (slides.length !== paginationButtons.length || slides.length === 1) {
      slides[0].classList.add('active');
      if (paginationButtons[0]) paginationButtons[0].classList.add('active');
      return;
    }

    let currentIndex = 0;
    let autoSlideInterval = null;

    // Функція для активації слайду за індексом
    const activateSlide = (index) => {
      slides[currentIndex].classList.remove('active');
      paginationButtons[currentIndex].classList.remove('active');
      currentIndex = index;
      slides[currentIndex].classList.add('active');
      paginationButtons[currentIndex].classList.add('active');
    };

    const showNextSlide = () => {
      const nextIndex = (currentIndex + 1) % slides.length;
      activateSlide(nextIndex);
    };

    const showPrevSlide = () => {
      const prevIndex = (currentIndex - 1 + slides.length) % slides.length;
      activateSlide(prevIndex);
    };

    // Функція, яка скидає автопрокрутку та запускає її заново після вибору слайду
    const showSlide = (index) => {
      clearInterval(autoSlideInterval);
      activateSlide(index);
      autoSlideInterval = setInterval(showNextSlide, 5000);
    };

    // Привʼязуємо обробники подій до кнопок стрілок, якщо вони знайдені
    prevButton?.addEventListener('click', showPrevSlide);
    nextButton?.addEventListener('click', showNextSlide);

    // Привʼязуємо обробники подій до кнопок пагінації
    paginationButtons.forEach((btn, index) => {
      btn.addEventListener('click', () => showSlide(index));
    });

    // Ініціалізація першого слайду та запуск автопрокрутки
    activateSlide(0);
    autoSlideInterval = setInterval(showNextSlide, 5000);
  });
}
